<template>
  <v-container fill-height class='full-screen pa-0 ma-0'>
    <div class="custom-body">
      <img src="@/assets/images/PLACE_NEGATIVO.png" alt="logo" class="logo">
      <div class="background-header"></div>

      <v-row class="space-top">
        <v-col cols="6" md="6" sm="12">
          <v-card flat class='login-card pa-16 authenticate-box'>
            <v-form id='form-authenticate' ref='form'>

              <v-row cols='12' class='mt-4 ml-4 mr-4' style="margin-bottom: 20px;">
                <p
                  class='font-weight-black pb-0 mb-1'
                  style="margin-left: 10px;font-size: 18px">
                  Escolha do seller
                </p>
              </v-row>

              <v-row
                cols='12'
                class='mt-3 ml-4 mr-4'
                style="max-height: 20rem;overflow-y: auto;margin-bottom: 25px;">
                <v-container

                  class="px-0"
                  fluid
                >

                  <v-radio-group v-model="seller" style="margin-left: 10px;">
                    <v-progress-circular
                      v-if="loadingInit"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-radio
                      v-for="item in sellers"
                      :key="item.id"
                      :value="item"
                    >
                      <template v-slot:label>
                        <label>
                          <b>{{ item.tradingName }}</b><br>
                          CNPJ {{ item.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') }}
                        </label>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-container>
              </v-row>
              <v-row cols='12' class='mt-3 ml-4 mr-4'>

                <v-btn
                  type='submit'
                  :disabled="!seller || loading"
                  depressed
                  color='primary'
                  class='text-button mr-2'
                  @click.prevent='submitForm()'>

                  Selecionar
                </v-btn>
                <v-btn
                  outlined
                  color='primary'
                  class='text-button  mr-2'
                  @click.prevent='cancelForm()'>
                  Cancelar
                </v-btn>
              </v-row>
            </v-form>

          </v-card>
        </v-col>
        <v-col cols="4" md="4" sm="7" class="centered-logos">
          <img
            src="@/assets/images/GenteBoaConectada.svg"
            alt="Gente boa conectada com a nossa gente"
            class="good-people space-top-good-people">
        </v-col>
        <v-col cols="2" md="2" sm="5" class="centered-logos">
          <img
            src="@/assets/images/PLACE_NEG_SIDE.svg"
            alt="X"
            class="place">
        </v-col>
      </v-row>
    </div>
  </v-container>

</template>

<script>

import { get } from '@/service/api';
import { MutationTypesEnum } from '@/types/main/mutation-types';
import { routerPush } from '@/service/kplaceRouter';

export default {
  name: 'Seller',
  data() {
    return {
      sellers: [],
      seller: null,
      loading: false,
      loadingInit: false,
    };
  },
  methods: {
    cancelForm() {
      routerPush('/login');
    },
    async submitForm() {
      try {
        if (this.seller) {
          this.loading = true;
          const currentUser = JSON.parse(localStorage.getItem('current_user'));
          currentUser.sellerId = this.seller.id;
          currentUser.companyName = this.seller.companyName;
          localStorage.setItem('sellerId', currentUser.sellerId);
          localStorage.setItem('current_user', JSON.stringify(currentUser));
          await routerPush('/product');
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    try {
      const currentUser = JSON.parse(localStorage.getItem('current_user'));
      this.loadingInit = true;
      const seller = currentUser?.sellers;
      get(`ui-integrator/sellers?sellers=${seller.toString()}`, {
        headers: {
          sellerId: currentUser?.sellerId,
        },
      }).then((response) => {
        this.sellers = response.data.register;
      });
    } finally {
      this.loading = false;
      this.loadingInit = false;
    }
  },
};
</script>
<style>
  .logo {
    height: 85px;
    margin: 20px 0 0 20px;
  }
</style>
